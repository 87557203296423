import { render, staticRenderFns } from "./OverviewTab.vue?vue&type=template&id=512db2b6&scoped=true"
import script from "./OverviewTab.vue?vue&type=script&lang=js"
export * from "./OverviewTab.vue?vue&type=script&lang=js"
import style0 from "./OverviewTab.vue?vue&type=style&index=0&id=512db2b6&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "512db2b6",
  null
  
)

export default component.exports