































































































import Vue from 'vue';
import vuex from '@/store';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Reports, User, Props, Global, Projects } from '@/store';
import cloneDeep from 'lodash/cloneDeep';
import FullViewReportPie from './components/reportPie/FullViewReportPie.vue';
import FullViewReportXY from './components/reportXY/FullViewReportXY.vue';
import FullViewReportBar from './components/reportBar/FullViewReportBar.vue';
import OverviewTab from './components/overview/OverviewTab.vue';
import AlertManagment from '@/views/AlertManagment.vue';
import moment from 'moment-timezone';
import PropUtils from '@/modules/PropUtils';
import Utils from '@/modules/Utils';

@Component({
  components: {
    FullViewReportPie,
    FullViewReportXY,
    FullViewReportBar,
    OverviewTab,
    AlertManagment
  }
})
export default class FullViewReports extends Vue {
  @User.State('isAuth') isAuth;
  @User.State('project') project;
  @Props.State('list') projectProperties;
  @Reports.State('reportsList') projectDevices;
  @Reports.State('devicesCount') devicesCount;
  @Global.State('lang') lang;
  @Global.State('overview_selected_tab') overview_selected_tab;
  @Global.State('timezone') projectTimezone;
  @Global.State('allProjectsTimezone') allProjectsTimezone;
  @Global.Mutation('setPageTitle') setPageTitle;
  @Global.Mutation('setOverviewSelectedTab') setOverviewSelectedTab;
  @Projects.State('list') projectsList;
  @Projects.State('projects_metadata') projectsMetadata;
  @Projects.State('projects_metadata_size') projectsMetadataSize;
  @Projects.State('alerts') alerts;
  @Projects.State('events') events;

  tab = 'tab-fullView';
  projects_chart = [];
  dates = [];
  weeklyDates = [];
  weeklyDevices = [{name: 'Devices', data: []}];
  commissionedLastMonth = [{name: 'Devices', data: []}];
  lastMonthCategories = [];
  barData = {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        columnWidth: '30%',
        borderRadius: 8
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    yaxis: {
      labels: {
        formatter: (val) => val.toFixed(0)
      }
    },
    labels: [],
    fill: {
      opacity: 1
    }
  };
  power = [];
  pole = [];
  screw = [
    { place: 'Regular', value: 0 },
    { place: 'Fragile', value: 0 },
    { place: 'Unspecified', value: 0 }
  ];
  activity = [
    { place: 'Active devices', value: 0 },
    { place: 'Inactive', value: 0 },
    { place: 'In installation', value: 0 },
    { place: 'Maintenance', value: 0 }
  ];
  painted =  [
    { place: 'Painted', value: 0 },
    { place: 'Not painted', value: 0 },
    { place: 'Unspecified', value: 0 }
  ];
  alerts_by_priority = [];
  events_by_priority = [];
  allAlerts = [];
  allEvents = [];
  allProjects = false;
  priorities_index = { 1: 2, 2: 1, 3: 0 };
  project_metadata = null;
  today_timestamp = null;
  START_TIME = '06:00:00';
  END_TIME = '06:00:00';
  metadata_calculated = new Map();
  project_timezone = null;
  isLoading = true;

  mounted() {
    if (this.overview_selected_tab) {
      this.tab = this.overview_selected_tab;
      this.setOverviewSelectedTab('');
    }
    this.allProjects = this.project.company === 'allProjects';
    this.setPageTitle('Projects Overview');
    this.setThresholdProperty();
    this.project_timezone = this.allProjects ? this.allProjectsTimezone : this.projectTimezone;
    if (!this.project_timezone) this.project_timezone = 'Asia/Tel_Aviv';
    this.today_timestamp = moment(`${moment.tz(this.project_timezone).format('YYYY-MM-DD')} ${this.END_TIME}`).valueOf();
    this.loadPage();
    this.getAlerts();
    this.getEvents();
  }

  setThresholdProperty() {
    const thresholdProperty = PropUtils.getProperty(this.projectProperties, 'meta.events_threshold');
    if (thresholdProperty && thresholdProperty.value){
      if (thresholdProperty.value.commissionedStart && thresholdProperty.value.commissionedEnd) {
        this.START_TIME = thresholdProperty.value.commissionedStart;
        this.END_TIME = thresholdProperty.value.commissionedEnd;
      }
    }
  }

  @Watch('projectsList', {immediate: true, deep: true})
  updateProjectsChart() {
    this.projects_chart = [];
    this.projectsList.forEach((project) => {
      this.projects_chart.push({
        id: project.group_id,
        pid: project.id,
        place: this.$t(project.name),
        value: 0
      });
    });
  }

  updateIsLoading(){
    if (this.projectsList.length === this.projectsMetadataSize && this.isLoading) {
      this.isLoading = false;
    }
  }

  @Watch('allProjectsTimezone')
  updateProjectTimezone(){
    if (this.project_timezone !== this.allProjectsTimezone){
      this.project_timezone = this.allProjectsTimezone;
      this.today_timestamp = moment(`${moment.tz(this.project_timezone).format('YYYY-MM-DD')} ${this.END_TIME}`).valueOf();
      this.setAllProjectsMetadata();
      this.updateIsLoading();
    }
  }

  @Watch('projectsMetadataSize')
  loadPage() {
    // update totalDevices
    const prev_data = cloneDeep(this.projects_chart);
    this.projects_chart = null;
    prev_data
      .filter((project) => this.projectsMetadata.has(project.pid) && this.projectsMetadata.get(project.pid).totalDevices !== project.value)
      .forEach((project) => project.value = this.projectsMetadata.get(project.pid).totalDevices);
    this.projects_chart = prev_data;
    this.allProjects ? this.setAllProjectsMetadata() : this.setCurrentProjectMetadata();
    this.updateIsLoading();
  }

  setAllProjectsMetadata(){
    const painted = [{ place: 'Painted', value: 0 }, { place: 'Not painted', value: 0 }, { place: 'Unspecified', value: 0 }];
    const activity = [{ place: 'Active devices', value: 0 }, { place: 'Electrical fault', value: 0 }, { place: 'In installation', value: 0 }, { place: 'Maintenance', value: 0}];
    const screw = [{ place: 'Regular', value: 0 }, { place: 'Fragile', value: 0 }, { place: 'Unspecified', value: 0 }];
    let poleTypeChartData = [];
    let powerTypeChartData = [];

    [...this.projectsMetadata.entries()].forEach(([project_id, data]) => {
      [0, 1, 2].forEach((index) => {
        painted[index].value += data.painted[index].value;
        activity[index].value += data.activity[index].value;
        screw[index].value += data.screw[index].value;
      });
      poleTypeChartData = this.countTypes(data.pole, poleTypeChartData);
      powerTypeChartData = this.countTypes(data.power, powerTypeChartData);
    });

    this.painted = painted.map((type) => ({place: this.$t(type.place), value: type.value}));
    this.activity = activity.map((type) => ({place: this.$t(type.place), value: type.value}));
    this.screw = screw.map((type) => ({place: this.$t(type.place), value: type.value}));
    this.pole = this.getFinalTypesList(poleTypeChartData);
    this.power = this.getFinalTypesList(powerTypeChartData);

    const commissioned_count_by_date = this.getDefaultCommissionedLastMonth();
    let commissioned_total = 0;
    let commissioned_last_month_total = 0;
    [...this.projectsMetadata.values()].forEach((project_metadata) => {
      const commissioned_last_month = this.getCommissionedByPeriod(project_metadata.commissionedByDate, 30);
      commissioned_last_month_total += commissioned_last_month.length;
      this.setCommissionedByDate(commissioned_last_month, commissioned_count_by_date);
      commissioned_total += project_metadata.commissionedByDate.length;
    });

    const commissioned_last_week_dates = this.getLastWeekDates(commissioned_count_by_date);
    this.barData.labels = commissioned_last_week_dates.map((date) => Utils.convertTimestamp(date, 'DD/MM', this.project_timezone));
    this.weeklyDevices[0].data = commissioned_last_week_dates.map((date) => commissioned_count_by_date[date]);
    const before_month_date = moment(this.today_timestamp).tz(this.project_timezone).subtract(30, 'days').valueOf();
    const commissioned_before_last_month = commissioned_total - commissioned_last_month_total;
    commissioned_count_by_date[before_month_date] += commissioned_before_last_month;
    const commissioned_dates = Object.keys(commissioned_count_by_date).sort((date1, date2) => date1.localeCompare(date2, undefined, {numeric: true, sensitivity: 'base'}));
    commissioned_dates.slice(1).forEach((date) => {
      const date_number = Number(date);
      const yesterday = moment(date_number).subtract(1, 'days').valueOf();
      commissioned_count_by_date[date_number] += commissioned_count_by_date[yesterday];
    });
    const commissioned_last_month_dates = Object.keys(commissioned_count_by_date).map((date) => Number(date)).sort((d1, d2) => d1 - d2);
    this.lastMonthCategories = commissioned_last_month_dates.map((date) => Utils.convertTimestamp(date, 'DD/MM', this.project_timezone));
    this.commissionedLastMonth[0].data = commissioned_last_month_dates.map((date) => commissioned_count_by_date[date]);
  }

  getFinalTypesList(chart_data){
    let final_chart_data = cloneDeep(chart_data);
    final_chart_data.sort((a, b) => b.value - a.value);
    const unspecifiedIndex = final_chart_data.findIndex((type) => type.place === 'Unspecified');
    let unspecifiedObject = { place: 'Unspecified', value: 0};
    if (unspecifiedIndex > -1){
      unspecifiedObject = final_chart_data[unspecifiedIndex];
      final_chart_data = final_chart_data.slice(0, unspecifiedIndex).concat(final_chart_data.slice(unspecifiedIndex + 1));
    }
    
    const otherIndex = final_chart_data.findIndex((type) => type.place === 'Other value');
    let otherObject = { place: 'Other value', value: 0 };
    if (otherIndex > -1){
      otherObject = final_chart_data[otherIndex];
      final_chart_data = final_chart_data.slice(0, otherIndex).concat(final_chart_data.slice(otherIndex + 1));
    }

    if (final_chart_data.length > 6) {
      final_chart_data.slice(5).forEach((type) => otherObject.value += Number(type.value));
      final_chart_data = final_chart_data.slice(0, 5);
    }

    if (unspecifiedObject.value) final_chart_data.push(unspecifiedObject);
    if (otherObject.value) final_chart_data.push(otherObject);

    return final_chart_data.map((type) => ({place: this.$t(type.place), value: type.value}));
  }

  countTypes(types, chart_data){
    if (!chart_data.length) return types;
    
    const types_counter = cloneDeep(chart_data);
    types.forEach((type) => {
      const typeIndex = types_counter.findIndex((type_in_chart) => type_in_chart.place === type.place);
      typeIndex === -1 ? types_counter.push(type) : types_counter[typeIndex].value += type.value;
    });
    return types_counter;
  }

  setCurrentProjectMetadata(){
    if (this.projectsMetadata.has(this.project.id)){
      const metadata = this.projectsMetadata.get(this.project.id);
      this.activity = metadata.activity.map((type) => ({place: this.$t(type.place), value: type.value}));
      this.painted = metadata.painted.map((type) => ({place: this.$t(type.place), value: type.value}));
      this.pole = metadata.pole.map((type) => ({place: this.$t(type.place), value: type.value}));
      this.power = metadata.power.map((type) => ({place: this.$t(type.place), value: type.value}));
      this.screw = metadata.screw.map((type) => ({place: this.$t(type.place), value: type.value}));
      [this.barData.labels, this.weeklyDevices[0].data, this.lastMonthCategories, this.commissionedLastMonth[0].data] = this.getCommissionedLastMonthWeek(metadata.commissionedByDate);
    }
  }

  getCommissionedLastMonthWeek(dates){
    const commissioned_last_month = this.getCommissionedByPeriod(dates, 30);
    const commissioned_count_by_date = this.getDefaultCommissionedLastMonth();
    this.setCommissionedByDate(commissioned_last_month, commissioned_count_by_date);
    const commissioned_last_week_dates = this.getLastWeekDates(commissioned_count_by_date);
    const bardata_labels = commissioned_last_week_dates.map((date) => Utils.convertTimestamp(date, 'DD/MM', this.project_timezone));
    const weekly_devices_data = commissioned_last_week_dates.map((date) => commissioned_count_by_date[date]);
    const before_month_date = moment(this.today_timestamp).tz(this.project_timezone).subtract(30, 'days').valueOf();
    const commissioned_before_last_month = dates.length - commissioned_last_month.length;
    commissioned_count_by_date[before_month_date] += commissioned_before_last_month;
    const commissioned_dates = Object.keys(commissioned_count_by_date).sort((date1, date2) => date1.localeCompare(date2, undefined, {numeric: true, sensitivity: 'base'}));
    commissioned_dates.slice(1).forEach((date) => {
      const date_number = Number(date);
      const yesterday = moment(date_number).subtract(1, 'days').valueOf();
      commissioned_count_by_date[date_number] += commissioned_count_by_date[yesterday];
    });
    const commissioned_last_month_dates = Object.keys(commissioned_count_by_date).map((date) => Number(date)).sort((d1, d2) => d1 - d2);
    const last_month_categories = commissioned_last_month_dates.map((date) => Utils.convertTimestamp(date, 'DD/MM', this.project_timezone));
    const commissioned_last_month_data = commissioned_last_month_dates.map((date) => commissioned_count_by_date[date]);
    return [bardata_labels, weekly_devices_data, last_month_categories, commissioned_last_month_data];
  }

  getDefaultCommissionedLastMonth(){
    const commissioned_count_by_date = {};
    Array.from({length: 30}, (v, i) => i).forEach((index) => {
      const date = moment(this.today_timestamp).tz(this.project_timezone).subtract(index + 1, 'days').valueOf();
      commissioned_count_by_date[date] = 0;
    });
    return commissioned_count_by_date;
  }

  setCommissionedByDate(dates, commissioned_by_date){
    dates.forEach((timestamp) => {
      const related_day = this.getRelatedCommissionDay(timestamp);
      commissioned_by_date[related_day]++;
    });
  }

  getLastWeekDates(last_month){
    return Object.keys(last_month).map((date) => Number(date)).sort((d1, d2) => d1 - d2).slice(-7);
  }

  getCommissionedByPeriod(commissioned_timestamp, days){
    return commissioned_timestamp.filter((date) => date && date < this.today_timestamp && date > this.today_timestamp - 86400000 * days); 
  }

  getRelatedCommissionDay(commission_timestamp){
    const timediff = this.today_timestamp - commission_timestamp;
    const daysdiff = Math.ceil(timediff / 86400000);
    return moment(this.today_timestamp).tz(this.project_timezone).subtract(daysdiff, 'days').valueOf();
  }

  getAlerts() {
    const cabinets_alerts = Object.keys(this.alerts).length
      ? this.allProjects
        ? Object.values(this.alerts).map((project_alerts_data) => project_alerts_data['cabinets'])
        : this.project.id && this.alerts[this.project.id]
          ? [this.alerts[this.project.id].cabinets]
          : []
      : [];
    
    this.allAlerts = [];
    this.alerts_by_priority = [];

    const new_alerts_list = [];
    const new_alerts_by_priority = [
      { place: 'High priority', value: 0 },
      { place: 'Medium priority', value: 0 },
      { place: 'Low priority', value: 0 }
    ];

    if (cabinets_alerts && cabinets_alerts.length) {
      cabinets_alerts.forEach((cabinets) => {
        Object.values(cabinets).forEach((cabinet_alerts_data) => {
          new_alerts_by_priority[this.priorities_index[cabinet_alerts_data['top_priority']]].value++;
          new_alerts_list.push(...cabinet_alerts_data['list']);
        });
      });
    }

    this.allAlerts = new_alerts_list;
    this.alerts_by_priority = new_alerts_by_priority.map((alert) => ({place: this.$t(alert.place), value: alert.value}));
  }

  getEvents() {
    const project_events = Object.keys(this.events).length 
      ? this.allProjects 
        ? Object.values(this.events).map((project_events_data) => Object.values(project_events_data['cabinets']).map((cabinet) => cabinet['list']).flat()).flat()
        : this.project.id && this.events[this.project.id]
          ? Object.values(this.events[this.project.id].cabinets).map((cabinet) => cabinet['list']).flat()
          : []
      : [];
            
    this.allEvents = [];
    this.events_by_priority = [];

    const new_events_by_priority = [
      { place: 'High priority', value: 0 },
      { place: 'Medium priority', value: 0 },
      { place: 'Low priority', value: 0 }
    ];

    if (project_events && project_events.length) {
      project_events.forEach((event) => {
        if (event['consumption_category'].toLowerCase() === 'consumption anomalies'){
          new_events_by_priority[0].value++;
        }else if (event['consumption_category'].toLowerCase() === 'abnormal data'){
          new_events_by_priority[1].value++;
        }else if (event['consumption_category'].toLowerCase() === 'consumption during the day'){
          new_events_by_priority[2].value++;
        }
      });
    }
    this.allEvents = project_events;
    this.events_by_priority = new_events_by_priority.map((event) => ({place: this.$t(event.place), value: event.value}));
  }

  @Watch('alerts', {immediate: true, deep: true})
  getAlertsWatcher() {
    this.getAlerts();
  }

  @Watch('events', {immediate: true, deep: true})
  getEventsWatcher() {
    this.getEvents();
  }

  @Watch('$store.state.Global.lang')
  getLang() {
    this.loadPage();
  }
}
