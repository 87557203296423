<template>
  <div grid-list v-if="items.length > 0">
    <v-layout row wrap class="mb-3">
      <v-flex lg4 v-for="item in items" :key="item.id">
        <v-card class="blue-rounded-card pa-4 cards" :class="lang === 'en' ? 'mr-3 my-1' : 'ml-3 my-1'" @click="pickLocation(item)" style="background-color: white !important">
          <v-layout row class="mb-3">
            <h2 style="color: #0b1146">{{ $t(item.name) }}</h2>
          </v-layout>
          <v-layout row class="mt-4">
            <v-flex xs4>
              <v-layout row class="ml-4">
                <v-layout column :class="lang === 'en' ? 'mr-2' : 'ml-2'">
                  <v-icon style="font-size: 34px; color: #0b1146">mdi-router-wireless</v-icon>
                </v-layout>
                <v-layout column>
                  <h2>{{ $t(item.devices) }}</h2>
                  <span>{{ $t('Devices') }}</span>
                </v-layout>
              </v-layout>
            </v-flex>
            <v-flex xs4>
              <v-layout row class="ml-4">
                <v-layout column :class="lang === 'en' ? 'mr-2' : 'ml-2'">
                  <v-icon style="font-size: 34px; color: #0b1146">mdi-bell-ring-outline</v-icon>
                </v-layout>
                <v-layout column>
                  <h2>{{ $t(item.alerts) }}</h2>
                  <span>{{ $t('Alerts') }}</span>
                </v-layout>
              </v-layout>
            </v-flex>
            <v-flex xs4>
              <v-layout row class="ml-4">
                <v-layout column :class="lang === 'en' ? 'mr-2' : 'ml-2'">
                  <v-icon style="font-size: 34px; color: #0b1146">mdi-calendar-clock</v-icon>
                </v-layout>
                <v-layout column>
                  <h2>{{ $t(item.events) }}</h2>
                  <span>{{ $t('Events') }}</span>
                </v-layout>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>

export default {
  name: 'OverviewTab',
  props: ['events', 'alerts', 'projects_data', 'location'],
  data() {
    return {
      items: []
    };
  },
  methods: {
    getAlerts(project_id) {
      return this.alerts && this.alerts[project_id]
        ? this.alerts[project_id].cabinets
        : this.$store.state.Projects.alerts[project_id] && this.$store.state.Projects.alerts[project_id].cabinets || [];
    },
    getEvents(project_id) {
      return this.events && this.events[project_id]
        ? Object.values(this.events[project_id].cabinets).map((cabinet) => cabinet.list).flat()
        : this.$store.state.Projects.events[project_id] && Object.values(this.$store.state.Projects.events[project_id].cabinets).map((cabinet) => cabinet.list).flat() || [];
    },
    pickLocation(item) {
      this.$router.push(`/${this.location}/alerts/${item.pid}`);
    }
  },
  computed: {
    lang() {
      return this.$store.state.Global.lang;
    }
  },
  mounted() {
    const tableData = [];
    if (this.projects_data && this.projects_data.length > 0) {
      this.projects_data.forEach((project_data) => {
        const project_alerts = this.getAlerts(project_data.pid);
        const project_events = this.getEvents(project_data.pid);

        tableData.push({
          name: project_data.place,
          devices: project_data.value,
          alerts: Object.keys(project_alerts).length,
          events: Object.keys(project_events).length,
          AllAlerts: project_alerts,
          AllEvents: project_events,
          pid: project_data.pid
        });
      });
    } else if (!this.$route.params.pid) {
      this.$store.state.Reports.allProjectsData.projects.forEach((project) => {
        const total_devices = 
          (
            this.$store.state.Projects.projects_metadata.has(project.id) && 
            this.$store.state.Projects.projects_metadata.get(project.id).totalDevices
          ) || 0;

        const project_alerts = this.getAlerts(project.id);
        const project_events = this.getEvents(project.id);

        tableData.push({
          name: project.name,
          devices: total_devices,
          alerts: Object.keys(project_alerts).length,
          events: Object.keys(project_events).length,
          AllAlerts: project_alerts,
          AllEvents: project_events,
          pid: project.id
        });
      });
    }
    this.items = tableData;
  }
};
</script>
<style scoped lang="stylus" scoped>
@import '../../../../assets/stylesheets/base/1.variables.styl';

.cards:hover {
  cursor: pointer;
  background: linear-gradient(#42489A, #814283, #9B416E);
  color: white;
  h2 {
    color: white !important;
  }

  .v-icon {
    color: white !important;
  }

  .span {
    color: white !important;
  }
}

.font-16 {
  color: #6a6a6a;
}
</style>